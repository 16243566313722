import React from 'react';

function Why() {
  return (
    <div>
      <h3>Why this project?</h3>
      <p> Our goal is to make it easier for you to discover and enjoy exciting sporting action from around the world. <br />
      With our centralized platform and the ability to access streaming content, <br />
      we're your one-stop site for all free sports streaming events.</p>
    </div>
  );
}

export default Why;
