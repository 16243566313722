import React from 'react';

function Events({ events }) {
  return (
    <div>
      <table>
        <thead>
          <tr>
            <th>Sport</th>
            <th>Date/Time</th>
            <th>Channel</th>
            <th>Event Detail</th>
          </tr>
        </thead>
        <tbody>
          {events.length > 0 ? (
            events.map((event, index) => (
              <tr key={index}>
                <td>{event.Sport}</td>
                <td>{event['Date/Time']}</td>
                <td>{event.Channel}</td>
                <td>{event['Event Detail']}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="4">No event found</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default Events;
