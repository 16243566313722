import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import './App.css';
import Events from './Events'; // Your Events component
import Why from './Why'; // Your Why component
import CommercialBanner from './CommercialBanner'; // Import the commercial banner
import Suggestion from './Suggestion'; // Import the Suggestion component
import { Analytics } from '@vercel/analytics/react';

function App() {
  const [isOpen, setIsOpen] = useState(false);
  const [events, setEvents] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]); // Store filtered events
  const [dateFilter, setDateFilter] = useState(''); // Date filter state
  const [sportFilter, setSportFilter] = useState(''); // Sport filter state
  const [sports, setSports] = useState([]); // State for dynamic sports list

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleMenuSelection = () => {
    setIsOpen(false); // Close the menu when a selection is made
  };

  // Fetch CSV data on component mount
  useEffect(() => {
    fetch('events.csv')
      .then((response) => response.text())
      .then((data) => {
        const parsedEvents = parseCSV(data);
        setEvents(parsedEvents);
        setFilteredEvents(parsedEvents); // Initially display all events
      })
      .catch((error) => console.error('Error fetching events:', error));
  }, []);


  // Parse CSV data into an array of objects
  const parseCSV = (data) => {
    const rows = data.trim().split('\n');
    const headers = rows[0].split(';').map(header => header.trim());
    return rows.slice(1).map((row) => {
      const values = row.split(';').map(value => value.trim());
      const obj = {};
      headers.forEach((header, index) => {
        obj[header] = values[index] !== undefined ? values[index] : ''; // Safely assign values
      });
      // Convert the event time from CET to local timezone
      obj['Date/Time'] = convertToUserTimezone(obj['Date/Time'],obj['Timezone']);
      return obj;
    });
  };

  // Function to convert a CET timestamp (in format "yyyy-mm-dd hh:mm") to the user's local timezone
  const convertToUserTimezone = (CSVTime, timezone) => {
    if (!CSVTime) return ''; // Handle empty values
    // Split the timezone to get the UCT difference
    var [dump, hourshift] = timezone.split('C');
    hourshift = Number(hourshift) // convert the csv time to UTC

    // Split the time into date and hour components
    const [date, time] = CSVTime.split(' ');
    const [year, month, day] = date.split('-').map(Number);
    const [hours, minutes] = time.split(':').map(Number);

    // Create a Date object and convert to UTC
    const cetDate = new Date(Date.UTC(year, month - 1, day, hours-hourshift, minutes)); // month - 1 is used because JavaScript Date months are zero-based (0 = January, 11 = December).

    // Convert to user's local timezone
    const userTimezoneDate = new Date(cetDate.toLocaleString('en-US', { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone }));

    // Format the result in "yyyy-mm-dd hh:mm"
    const formattedDate = userTimezoneDate.getFullYear() +
      '-' + String(userTimezoneDate.getMonth() + 1).padStart(2, '0') +
      '-' + String(userTimezoneDate.getDate()).padStart(2, '0') +
      ' ' + String(userTimezoneDate.getHours()).padStart(2, '0') +
      ':' + String(userTimezoneDate.getMinutes()).padStart(2, '0');

    return formattedDate;
  };

  // Filter and sort events based on selected filters and Date/Time
  const filterEvents = () => {
    const now = new Date(); // Current date and time

    const filtered = events.filter((event) => {
      // Parse event date and time
      const eventDate = event['Date/Time'] ? new Date(event['Date/Time']) : null;

      // Include events that are within the last hour or in the future
      const isFutureOrRecent = eventDate && (eventDate >= now || eventDate >= new Date(now.getTime() - 300 * 60 * 1000));
      
      // Match sport filter if applied
      const sportMatches = !sportFilter || (event.Sport && event.Sport.toLowerCase() === sportFilter.toLowerCase());

      return isFutureOrRecent && sportMatches; // Combine both filters
    });

    // Sort filtered events by Date/Time
    const sortedEvents = filtered.sort((a, b) => {
      const dateA = new Date(a['Date/Time']);
      const dateB = new Date(b['Date/Time']);
      return dateA - dateB; // Ascending order
    });

    // Update filtered events state
    setFilteredEvents(sortedEvents);

    // Update sports list dynamically based on filtered events
    const uniqueSports = [...new Set(sortedEvents.map(event => event.Sport).filter(Boolean))];
    uniqueSports.sort(); // Sort alphabetically
    setSports(uniqueSports); // Update sports state
  };


  // Handle input changes for filters
  const handleDateChange = (e) => {
    setDateFilter(e.target.value); // Update date filter state
  };

  const handleSportChange = (e) => {
    setSportFilter(e.target.value); // Update sport filter state
  };

  // Use useEffect to filter events when dateFilter or sportFilter changes
  useEffect(() => {
    filterEvents();
  }, [dateFilter, sportFilter, events]); // Dependencies include filters and events

  return (
    <Router>
      <div className="App">
        <div className="banner1">
          <div className="hamburger" onClick={toggleMenu}>
            <div className={`line ${isOpen ? 'open' : ''}`}></div>
            <div className={`line ${isOpen ? 'open' : ''}`}></div>
            <div className={`line ${isOpen ? 'open' : ''}`}></div>
          </div>
          <h1 className="banner-title">FreeSportBroadcasts</h1>
        </div>
        <div className="banner2">
          <h1 className="banner-title">Free Sports on Public TV Guide</h1>
        </div>

        {isOpen && (
          <div className="navbar">
            <Link to="/" onClick={handleMenuSelection}>Free Sports TV Events</Link>
            <Link to="/why" onClick={handleMenuSelection}>Why</Link>
          </div>
        )}

        <div className="content">
          {/* Add the commercial banner here */}
          <CommercialBanner />
          {/* Date Filter */}
          {/*
          <div className="filter-group">
            <label htmlFor="dateFilter">Filter by Date:</label>
            <input type="date" id="dateFilter" value={dateFilter} onChange={handleDateChange} />
          </div>
           */}

          {/* Sport Filter */}
          <div className="filter-group">
            <label htmlFor="sportFilter">Filter by Sport:</label>
            <select id="sportFilter" value={sportFilter} onChange={handleSportChange}>
              <option value="">All Sports</option>
              {sports.map((sport) => (
                <option key={sport} value={sport}>{sport}</option>
              ))}
            </select>
          </div>

           <Routes>
            <Route path="/" element={<Events events={filteredEvents} />} />
            <Route path="/why" element={<Why />} />
           </Routes>

          {/* Footer with Twitter link */}
          <footer>
            <p>
              <a href="https://x.com/FreeSportOnAir" target="_blank" rel="noopener noreferrer">
                <img src="/x-logo.png" alt="X Logo" style={{ width: "20px", verticalAlign: "middle", marginRight: "8px" }} />
                Follow us @FreeSportOnAir

              </a>
            </p>
          </footer>
        </div>
      </div>
      {/* Include Analytics component here */}
      <Analytics />
    </Router>
  );
}

export default App;