// CommercialBanner.js
import React from 'react';
import './CommercialBanner.css'; // Optional: Create a CSS file for styling
import NordVPNlogo from './NordVPNlogo.png'; // Place the logo image in the same directory or update the path

const CommercialBanner = () => {
  return (
    <div className="commercial-banner">
      <img src={NordVPNlogo} alt="Surfshark Logo" className="logo" />
      <div className="banner-content">
        <a href="https://go.nordvpn.net/aff_c?offer_id=15&aff_id=112390&url_id=858" target="_blank" rel="noopener noreferrer">
          Special Deal <br />
          Support us! <br />
          Click here for more details
        </a>
      </div>
    </div>
  );
};

export default CommercialBanner;
